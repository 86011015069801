import { memo as Memo } from 'react';
import Link from 'next/link';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

const CustomLink = Memo(({ url, as, className, nav, disableCr, prefetch = false, scroll, selectedLang, ...props }) => {
    return (
        <Link
            scroll={scroll}
            prefetch={prefetch || undefined}
            target={props.target || '_self'}
            className={`${!disableCr && 'crCircle'} ${className || ''}`}
            href={typeof url === 'object' ? { ...url, pathname: `/[locale]${url.pathname}` } : `/[locale]${url}`}
            as={`/${selectedLang || 'en'}${as || `${url}`}`}
            aria-label={props.ariaLabel || (props.name || typeof url == 'object' ? url.pathname : `/${selectedLang}${as || url}`)}
        >
            {props.children || props.content}
        </Link>
    );
});

export default withLanguageContext(CustomLink, ['selectedLang']);
